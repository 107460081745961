export const ALL_ROUTES = {
    LOGIN : "/",
    
    FORGET_PASSWORD: "/forget-password",
    CHANGE_PASSWORD: "change-password",
    PROFILE: "/admin/profile",

    DASHBOARD: "admin",
    DASHBOARD_INDEX_ROUTE: 'dashboard',
    AFTER_LOGIN: '/admin/dashboard',

    CARLIST: "cars",
    ADD_NEW_CAR: "add-new-car",
    ADD_NEW_CAR_BUTTON: "/admin/add-new-car",
    AFTER_SUBMIT_CAR_DETAILS: '/admin/cars',

    MAKERS: 'brand',
    ADD_NEW_MAKE: '/admin/add-new-brand',
    AFTER_SUBMIT_MAKE_DETAILS: '/admin/brand',

    MODELS: 'models',
    ADD_NEW_MODEL: '/admin/add-new-model',

    VARIENTS: "variants",
    AFTER_SUBMIT_VARIENT: "/admin/variants",
    ADD_NEW_VARIENTS: 'add-new-variants',
    ADD_NEW_VARIENTS_BUTTON: '/admin/add-new-variants',

    ADD_USER: "/admin/add-new-user",
    ADMIN_USER_LIST: "users",
    AFTER_SUBMIT_USER_DETAILS: '/admin/users',

    SOLD_CARS: 'soldcars',
    PORTFOLIO: "/admin/portfolio",


    BLOG: "/admin/blog",
    NEW_BLOG:"/admin/new-blog",
   
    _ID: ':id',

    NOT_FOUND: "/*",
    UNAUTHORISED: "/unauthorised",

    LEADS: "/admin/leads",
    NOTIFICATIONS: "/admin/notifications",

    Investment_Management:"/admin/investment-management",
    ADD_Expense_Button: "/admin/add-expense",
    INVOICE_GENERATOR:"/admin/invoice-generator",
    SALES_INVOICE:"/admin/invoice-generator/sales-invoice",
    PURCHASE_INVOICE:"/admin/invoice-generator/purchase-invoice",
    EDIT_SALES_INVOICE:"/admin/add-edit-invoice/sales",
    EDIT_PURCHASE_INVOICE:"/admin/add-edit-invoice/purchase"
}

// export const USER_ROUTES_PERMISSIONS = ['user', 'superadmin', 'manager']

// export const MANAGER_ROUTES_PERMISSIONS = ['superadmin', 'manager']

// export const SUPERADMIN_ROUTES_PERMISSIONS = ['superadmin']
