import { createReducer } from "@reduxjs/toolkit";

export const rootReducer = createReducer(
    { isAuthenticated: false },
    {
        login: (state) => {
            state.isAuthenticated = true
        },
        logout: (state) => {
            state.isAuthenticated = false;
        }
    })

export const emailReducer = createReducer(
    { isemail: "" },
    {
        sendOtp: (state, action) => {
            state.isemail = action.payload
            // console.log("isemail",state.isemail)
        },
    })