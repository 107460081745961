import axios from "axios";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../constants/errorMessages";

// export const baseUrl = process.env.REACT_APP_BASE_URL || `http://192.168.1.57:4001/api`

const api = axios.create({
  //baseURL: 'https://explicitcars.com/api/',
   baseURL: 'https://explicitcars.com/api/',
  //  baseURL: 'http://localhost:4001/api/',
  // baseURL:'http://192.168.1.68:4001/api/',
  headers: { 'Content-Type': 'application/json' },
})

export const authInterceptor = api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'));

    if (token !== '') {
      config.headers['authorization'] = token ? `Bearer ${token}` : '';
    }
    return config;
  },
  (error) => {
    console.log("instance error >>>", error);
    Promise.reject(error);
  }
);

let isErrorMessageDisplayed = false;
export const authInterceptorResponse = api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error) {
      console.log('error apiCOnfig response', error);
      if (error?.response?.status === 401) {
        localStorage.clear();
        if (error?.config?.url.includes('/user/signin')) return
        window.location = "/";
      } else if (error?.response?.data?.message && !isErrorMessageDisplayed) {
        toast.error(error?.response?.data?.message);
        isErrorMessageDisplayed = true;
        setTimeout(() => {
          isErrorMessageDisplayed = false;
        }, 6000); 
      } else if (!isErrorMessageDisplayed) {
        toast.error(ERROR_MESSAGES.CATCH_ERROR);
        isErrorMessageDisplayed = true;
        setTimeout(() => {
          isErrorMessageDisplayed = false;
        }, 3000);
      }
    }
  }
);
export default api;