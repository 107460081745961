export const API_ENDPOINTS  = {
    LOGIN: '/user/signin',
    GET_ALL_CARS: '/car/getAll/',
    GET_SINGLE_CAR_DATA:'/car/detail',
    GET_ALL_BRANDS:'/brand/getAll',
    GET_ALL_MODEL: '/model/',
    ADD_NEW_VARIENT:'/varient/add',
    GET_MODELS_OF_BRANDS: '/model/',
    GET_BRANDS_OF_VARIENTS: '/varients/',
    SEND_OTP: 'user/sendotp',
    VERIFY_OTP:'user/verifyotp',
    RESET_PASSWORD: 'user/resetPassword',
    CHANGE_PASSWORD: 'user/changePassword',
    ADD_VARIENT: '/varient/add',
    GET_ALL_ROLES:'/getAllRoles',
    USER_SIGNUP:'/user/signup',
    MODEL_GET_ALL:'/modelGetAll',
    GET_ALL_VARIENT:`get/allVarient`,
    VARIENT_DELETE:'varient/delete/',
    UPDATE_CAR:'car/',
    UPDATE_CAR_FEATURED:'carfeatured/',
    ADD_EXPENSES:'expenses/add',
    GET_EXPENSES_BY_ID:'expenses/getid',
    DELETE_EXPENSES:'expenses/delete',
    ADD_INVOICE:'car/addinvoice',
    GET_ALL_INVOICES:'car/getAllInvoices',
    GET_INVOICE_BY_ID:'car/getInvoiceById',
    UPDATE_INVOICE_BY_ID:'update/Invoice'
}