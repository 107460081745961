import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [authRole, setAuthRole] = useState({});
    const [carId, setCarId] = useState();
    const [loading, setLoading] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    // handle confirmation modal
    const [showModal, setshowModal] = useState(false)

    // Search values 
    const [searchValue, setSearchValue] = useState('')

    const handleSearch = (e) =>{
        setSearchValue(e.target.value)
    }
    // console.log('SEARCH VALUES auth context', searchValue)

    return (
        <AuthContext.Provider value={{
            auth,
            setAuth,
            authRole,
            setAuthRole,
            carId,
            setCarId,
            loading,
            setLoading,
            setIsSidebarOpen,
            isSidebarOpen,
            setshowModal,
            showModal,
            searchValue,
            handleSearch,
            setSearchValue
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;