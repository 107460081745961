import React from "react";

import sometingwrong from '../../src/images/page-not-fount.svg';


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      this.setState({ hasError: true })
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <>
               <div className=' mx-auto pt-9 pb-20'>
                  <div className='text-center'>
                      {/* <Image src={sometingwrong} alt="sometingwrong" className='mx-auto w-280'/> */}
                      <img src={sometingwrong} alt="My Image" />  
                      <h2 className='text-24 text-secondary font-semibold text-center my-5'>Something went wrong!</h2>
                       
                  </div>
                  
                </div>
            </>
        )
      }
  
      return this.props.children;
    }
  }

  export default ErrorBoundary;