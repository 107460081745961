import api from "./apiConfig";
// import { baseUrl } from "./apiConfig";


// POST Request
export const postData = async (endpoint, data) => {

  try {
    const response = await api.post(`${endpoint}`, data);
    return response;
  } catch (error) {

    console.error(error);
    throw error;
  }
};



// GET request
export const getData = async (endpoint) => {
  try {
    const response = await api.get(`${endpoint}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// PUT Request
export const putData = async (endpoint, data) => {
  try {
    const response = await api.put(`${endpoint}`, data);
    return response;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

// DELETE Request
export const deleteData = async (endpoint) => {
  try {
    const response = await api.delete(`${endpoint}`);
    return response;
  } catch (error) {
    console.log(error)
    throw error;
  }
}