import React from 'react'
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';


const AddFormHead = (props) => {
  const { heading, backRoute } = props;
  const navigate = useNavigate()
  return (
    <>
      <div className='flex-grow text-gray-800  overflow-auto p-4 pl-0 mb-1 '>
        <div className='md:flex block flex-shrink-0 items-center ml-auto justify-between'>
          <div className='mr-8 cursor-pointer text-2xl font-semibold flex items-center justify-start gap-2 cursor-default ms-3 px-1'>
            <div onClick={() => navigate(backRoute, { replace: true })} className='cursor-pointer'>
              <IoIosArrowBack />
            </div>
            {heading}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFormHead;
