import React, { Suspense } from 'react';
import './App.css';
import IndexRouter from './routes/indexRouter';
import ErrorBoundary from './components/errorBoundary';
import Loader from './components/loader';
import useAuth from './hooks/useAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { loading } = useAuth()

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<div><h1><Loader /></h1></div>}>
          {loading ? <Loader /> : 
          <>
          <ToastContainer position="top-center" />
          <IndexRouter />
          </>
          }
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
