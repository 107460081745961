import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ErrorBoundary from '../../../../components/errorBoundary';
import { ALL_ROUTES } from '../../../../constants/routesConfig';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AddFormHead from '../../../../components/addFormHead';
import { postData, getData, putData } from '../../../../api/apiController';
import { toast } from 'react-toastify';
import { API_ENDPOINTS } from '../../../../api/apiEndpoints';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddEditInvoice = () => {
    const navigate = useNavigate()

    const id = useParams()
    const ids = id?.id

    var name = id['*']
    name = name.split("/");
    const invoiceName = name[2];


    const [price, setPrice] = useState("");
    const [purchaseDate, setPurchaseDate] = useState(new Date())
    const [partyName, setPartyName] = useState("")


    const InvoiceSchema = Yup.object().shape({
        party_name: Yup.string().required('Party Name is required'),
        price: Yup.string().required('Price is required'),
        purchase_date: Yup.string().required('Date is required'),
    });

    useEffect(() => {
        getParticularInvoice()
    }, [])


    const getParticularInvoice = async () => {
        try {
            const result = await getData(`${API_ENDPOINTS.GET_INVOICE_BY_ID}/${ids}`)
            if (result.status === 200) {
                setPartyName(result.data?.data[0]?.party_name)
                setPrice(result?.data?.data[0]?.price)
            }
            else {
                console.log("error")
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleSubmit = async (values) => {
        try {
            const response = await putData(`${API_ENDPOINTS.UPDATE_INVOICE_BY_ID}/${ids}`, {
                party_name: values.party_name,
                purchase_date: values.purchase_date,
                price: values.price,
            });

            if (response.status === 200) {
                toast.success(response?.data?.message);
                navigate(ALL_ROUTES.Investment_Management)
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            console.error("Error updating invoice", error);
        }
    };


    var salesObject = {
        party_name: partyName,
        purchase_date: purchaseDate,
        price: price,
    }

    var purchaseObject = {
        party_name: partyName,
        purchase_date: purchaseDate,
        price: price,
    }


    return (
        <ErrorBoundary>
            <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.5 }}
            >
                <div className='py-10 px-10 min-h-screen'>
                    <AddFormHead heading='Edit Invoice' backRoute={ALL_ROUTES.Investment_Management} />
                    <Formik
                        initialValues={invoiceName == 'sales' ? salesObject : purchaseObject}
                        validationSchema={InvoiceSchema}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <div>
                                <label htmlFor="party_name">Party Name:</label>
                                <Field type="text" id="party_name" name="party_name" />
                                <ErrorMessage name="party_name" component="div" className="error" style={{ color: 'red' }} />
                            </div>

                            {invoiceName == 'sales' ?
                                <div>
                                    <label htmlFor="purchase_date">Sales Date:</label>
                                    <DatePicker
                                        id='purchase_date'
                                        selected={purchaseDate}
                                        onChange={(date) => setPurchaseDate(date)}
                                    />
                                    <ErrorMessage name="purchase_date" component="div" className="error" style={{ color: 'red' }} />
                                </div> :
                                <div>
                                    <label htmlFor="purchase_date">Purchase Date:</label>
                                    <DatePicker
                                        id='purchase_date'
                                        selected={purchaseDate}
                                        onChange={(date) => setPurchaseDate(date)}
                                    />
                                    <ErrorMessage name="purchase_date" component="div" className="error" style={{ color: 'red' }} />
                                </div>}

                            {invoiceName == 'sales' ?
                                <div>
                                    <label htmlFor="price">Sales Price:</label>
                                    <Field type="text" id="price" name="price" />
                                    <ErrorMessage name="price" component="div" className="error" style={{ color: 'red' }} />
                                </div>
                                :
                                <div>
                                    <label htmlFor="price">Purchase Price:</label>
                                    <Field type="text" id="price" name="price" />
                                    <ErrorMessage name="price" component="div" className="error" style={{ color: 'red' }} />
                                </div>
                            }
                            <button
                                type="submit"
                                className='w-full md:w-52 p-3 mt-10 font-semibold
        text-white rounded-md bg-orange border border-orange r gap-x-2.5 hover:bg-fade hover:text-black 
        transition-all duration-500'
                            >
                                Submit
                            </button>
                        </Form>
                    </Formik>
                </div>
            </motion.div>
        </ErrorBoundary>
    );
};

export default AddEditInvoice;