import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const getToken = () => {
  let token = localStorage.getItem('token')
  if (token) return token;
  else return false;
}

export function getRole(role) {
  let userRole;
  switch (role) {
    case 1:
      userRole = "superadmin";
      break;
    case 2:
      userRole = "admin";
      break;
    case 3:
      userRole = "user";
      break;
    case 4:
      userRole = "manager";
      break;
    default:
      userRole = "user";
  }
  return userRole;
}

// Prepend Select value on front of array
export const addSelectValue = (arr) => {
  const oneRecord = { id: '', name: "Select Cars" }
  if (arr.length > 0) {
    arr?.unshift(oneRecord)
    return arr;
  }
  else {
    return false;
  }
}

// Prepend Select value on front of array
export const addSelectValueMaker = (arr) => {
  const oneRecord = { id: '', maker_name: "Select Cars" }
  if (arr.length > 0) {
    arr?.unshift(oneRecord)
    return arr;
  }
  else {
    return false;
  }
}


// Select year picker generator
export const geneRateYear = () => {
  const currentDate = new Date().getFullYear();
  const yearSelect = Array.from({ length: 25 }, (_, i) => ({
    id: currentDate - i,
    name: currentDate - i,
  }));

  yearSelect.unshift({ id: "", name: "Select Year" });

  return yearSelect;
};

// Redierct function
export const RedirectUrl = (url) =>{
    useNavigate(url)
}

// Date Format 
export const dateFormat = (date) =>{
  const options = { day: '2-digit', month: 'short', year: 'numeric',weekday: 'long' };
   const changeDate = new Date(date).toLocaleDateString('en-US', options)
   return changeDate.toString()
}