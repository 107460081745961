// import React from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';


// const Schema = Yup.object().shape({
//   engine_specs: Yup.array().of(Yup.object().shape({
//     mileage: Yup.number().required('Mileage is required'),
//     sunroof: Yup.boolean(),
//   })),
//   safety: Yup.array().of(Yup.object().shape({
//     airbags: Yup.string().required('Airbags is required'),
//     test: Yup.string().required('Test is required'),
//   })),
// })


// function About() {
//   const initialValues = {
//     engine_specs: [
//       { mileage: '' },
//       { sunroof: false }
//     ],
//     safety: [
//       { airbags: '' },
//       { test: '' }
//     ]
//   };

//   const onSubmit = (values) => {
//     console.log("values>>>>!!!1", values);
//   };

//   return (
//     <div>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={Schema}
//         onSubmit={onSubmit}
//       >
//         <Form>
        
//           <Field type="number" id="engine_specs[0].mileage" name="engine_specs[0].mileage" placeholder="Mileage" />
//           <ErrorMessage name="engine_specs[0].mileage" component="div" />

//           <Field type="checkbox" id="engine_specs[1].sunroof" name="engine_specs[1].sunroof" />
//           <ErrorMessage name="engine_specs[1].sunroof" component="div" />

   
//           <Field type="text" id="safety[0].airbags" name="safety[0].airbags" placeholder="Airbags" />
//           <ErrorMessage name="safety[0].airbags" component="div" />

//           <Field type="checkbox" id="safety[1].test" name="safety[1].test" />
//           <ErrorMessage name="safety[1].test" component="div" />

//           <button type="submit">Submit</button>
//         </Form>
//       </Formik>
//     </div>
//   );
// }

// export default About;


import React from 'react';
import {  Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';

const initialValue = {
  service_Second_Section: {
    Second_service_Types_section: [
      {
        st_title: '',
        st_descriptions: '',
      },
      {
        st_title: '',
        st_descriptions: '',
      },
    ]
  }
}



const validationSchema = Yup.object().shape({
  service_Second_Section: Yup.object().shape({
    Second_service_Types_section: Yup.array()
      .of(
        Yup.object().shape({
          st_title: Yup.string().required('title Required'),
          st_descriptions: Yup.string().required("descriptions is required"),
        })
      )

  })




});










const About=()=>{
  return(
    <>
<div >
      
      <div>
        
        <div className='card1 w-full mb-5 flex xl:flex-nowrap lg:flex-wrap md:flex-wrap sm:flex-wrap flex-wrap items-center xl:justify-between lg:justify-between md:justify-between md:flex md:text-start sm:justify-between justify-center sm:flex sm:text-left grid text-center'>
          <Formik
            initialValues={initialValue}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              // console.log("service=>>>>>>>>>>>>>Data", values);
            }}
          >
            {(formikProps) => (
              <Form className="w-full">
                <div className="w-full">
             
                  <div className="mb-3 mt-7">
                  </div>
                  <div className="mb-3 mt-7">
                    <h3 className="font-bold text-2xl mb-4 text-orange">Service Types Section</h3>
                    <FieldArray name="service_Second_Section.Second_service_Types_section">
                      {({ push, remove }) => (
                        <div className="">
                          {formikProps.values.service_Second_Section.Second_service_Types_section.map((type, index) => (
                            <div key={index} className="w-full mb-6 bg-white p-6 rounded-lg">
                              <div className="w-full flex flex-wrap justify-between" >
                                <div className="md:w-49% w-full">
                                  <div className="mb-3">
                                    <label htmlFor={`st_title_${index}`} className="font-bold	">Title:</label>
                                    <Field type="text" id={`st_title_${index}`} name={`service_Second_Section.Second_service_Types_section[${index}].st_title`} placeholder="Enter Title" className="p-2 border-2 border-gray-200 rounded w-full focus:outline-none " />
                                    <div className="text-red">
                                      <ErrorMessage name={`service_Second_Section.Second_service_Types_section[${index}].st_title`} />
                                    </div>
                                  </div>
                                  <div>
                                    <label htmlFor={`st_descriptions_${index}`} className="font-bold	">Descriptions:</label>
                                    <Field component="textarea" type="text" id={`st_descriptions_${index}`} name={`service_Second_Section.Second_service_Types_section[${index}].st_descriptions`} placeholder="Enter Descriptions" className="p-2 border-2 border-gray-200 rounded w-full focus:outline-none h-24 resize-none" />
                                    


                                    <div className="text-red">
                                      <ErrorMessage name={`service_Second_Section.Second_service_Types_section[${index}].st_descriptions`} />
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                              <div className="flex justify-end">
                                <button type="button" className="duration-700 hover:bg-black bg-orange flex items-center justify-center px-5 h-10 text-white rounded-3xl mt-2" onClick={() => remove(index)}>Remove </button>
                              </div>
                            </div>
                          ))}
                          <div className="w-full mb-3 text-right">
                            <button type="button" className="duration-700 hover:bg-orange ml-auto	bg-black flex items-center justify-center h-10 px-5 text-base text-white rounded-3xl mt-2" onClick={() => push({ st_title: '', st_descriptions: ''})}>
                            Add Service Type
                            </button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                      
                  
                
                </div>                                    
                <div className="flex justify-center p-8">
                  <button type="submit" className="bg-orange border	border-orange px-11 py-2 text-white rounded-3xl text-lg hover:bg-white hover:text-orange duration-700">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>   

    
    </>
  )
}

export default About