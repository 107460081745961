import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import PageNotFound from '../pages/pageNotFound';
import RequireAuth from './requireAuth';
import { ALL_ROUTES } from '../constants/routesConfig';
import About from '../pages/about';
import AddEditInvoice from '../pages/dashboard/adminComponents/investmentManagement/addEditInvoice';

// import InvoiceGenerator from '../pages/dashboard/adminComponents/investmentManagement/invoiceGenerator';
// import ManagerDashboard from '../pages/dashboard/managerComponents/index';
// import UserDashboard from '../pages/dashboard/user';

const Dashboard = lazy(() => import('../pages/dashboard'));
const Login = lazy(() => import('../pages/login/login'));
const ForgetPassword = lazy(() => import('../pages/forget-password/forgetPassword'));
const ChangePassword = lazy(() => import('../pages/change-password/changePassword'))
const Profile = lazy(() => import("../pages/profile/profile"))
const CarList = lazy(() => import('../pages/dashboard/adminComponents/cars/carlist'));
const SingleCarDetails = lazy(() => import('../pages/dashboard/adminComponents/cars/singleCarDetails'));
const AddCar = lazy(() => import('../pages/dashboard/adminComponents/cars/addCar'));
const AddNewVarient = lazy(() => import('../pages/dashboard/adminComponents/varients/addNewVarient'));
const AdminDashboard = lazy(() => import('../pages/dashboard/adminComponents/dashboard/dashboard'));
const UsersList = lazy(() => import('../pages/dashboard/adminComponents/admin-users/usersList'));
const AddNewUser = lazy(() => import('../pages/dashboard/adminComponents/admin-users/addNewUser'));
const EditUsers = lazy(() => import('../pages/dashboard/adminComponents/admin-users/editUsers'));
// const AddBrandAndVarient = lazy(() => import('../pages/dashboard/adminComponents/make/addBrandAndVarient'));
const MakerList = lazy(() => import('../pages/dashboard/adminComponents/makes/makersList'));
const EditVarientDetails = lazy(() => import('../pages/dashboard/adminComponents/varients/editVarientDetails'));
const VarientList = lazy(() => import('../pages/dashboard/adminComponents/varients/varientList'));
const ModelsList = lazy(() => import('../pages/dashboard/adminComponents/models/modelsList'));
const AddMake = lazy(() => import('../pages/dashboard/adminComponents/makes/addMake'));
const SoldCars = lazy(() => import('../pages/dashboard/adminComponents/soldCars/soldCars'));
const Portfolio = lazy(() => import('../pages/dashboard/adminComponents/portfolio/portfolio'));
const Blog = lazy(() => import('../pages/dashboard/blogs/bloglist'))
const SingleBuyCardetails = lazy(() => import('../pages/dashboard/adminComponents/soldCars/singleBuyCarDetails'))
const Lead = lazy(() => import('../pages/dashboard/adminComponents/leads/leads'))
const SingleLeadsDetails = lazy(() => import('../pages/dashboard/adminComponents/leads/singleLeadsDetail'));
const Notifications = lazy(() => import('../pages/dashboard/adminComponents/notifications/notifications'));
const SingleNotificationDetails = lazy(() => import('../pages/dashboard/adminComponents/notifications/singleNotificationDetail'));
const InvestmentManagement = lazy(() => import('../pages/dashboard/adminComponents/investmentManagement/investmentManagement'))
const AddExpense = lazy(() => import('../pages/dashboard/adminComponents/investmentManagement/addExpense'))
const InvoiceGenerator = lazy(() => import('../pages/dashboard/adminComponents/investmentManagement/invoiceGenerator'))


function IndexRouter() {

    return (
        <div>
            <Routes>
                {/* Public routes */}

                <Route path={ALL_ROUTES.LOGIN} element={<Login />} />
                <Route path={ALL_ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />

                <Route path='/about' element={<About />} />

                {/* Routes for Super Admin */}
                {/* <Route element={<RequireAuth allowedRoles={['superadmin']} />}> */}
                <Route element={<RequireAuth allowedRoles={['superadmin', 'admin', 'manager']} />}>
                    <Route path={ALL_ROUTES.DASHBOARD} element={<Dashboard />}>
                        <Route index path={ALL_ROUTES.DASHBOARD_INDEX_ROUTE} element={<AdminDashboard />} />
                        <Route element={<RequireAuth allowedRoles={['superadmin', 'admin']} />}>
                            <Route path={ALL_ROUTES.CARLIST} element={<CarList />}>
                                <Route path={ALL_ROUTES._ID} element={<SingleCarDetails />} />
                            </Route>
                            <Route path={ALL_ROUTES.ADD_NEW_CAR} element={<AddCar />} />
                        </Route>
                        <Route element={<RequireAuth allowedRoles={['superadmin', 'admin', 'manager']} />}>
                            <Route path={ALL_ROUTES.VARIENTS} element={<VarientList />}>
                                <Route path={ALL_ROUTES._ID} element={<EditVarientDetails />} />
                            </Route>
                            <Route path={ALL_ROUTES.ADD_NEW_VARIENTS} element={<AddNewVarient />} />
                        </Route>
                        <Route element={<RequireAuth allowedRoles={['superadmin', 'admin']} />}>
                            <Route path={ALL_ROUTES.ADMIN_USER_LIST} element={<UsersList />} >
                                <Route path={ALL_ROUTES._ID} element={<EditUsers />} />
                            </Route>
                            <Route path={ALL_ROUTES.ADD_USER} element={<AddNewUser />} />
                        </Route>
                        <Route element={<RequireAuth allowedRoles={['superadmin', 'admin', 'manager']} />}>
                            <Route path={ALL_ROUTES.SOLD_CARS} element={<SoldCars />} >
                                <Route path={ALL_ROUTES._ID} element={<SingleBuyCardetails />} />
                            </Route>


                            <Route path={ALL_ROUTES.PORTFOLIO} element={<Portfolio />}>

                            </Route>


                            <Route path={ALL_ROUTES.BLOG} element={<Blog />}>

                            </Route>

                            <Route path={ALL_ROUTES.LEADS} element={<Lead />}>
                                <Route path={ALL_ROUTES._ID} element={<SingleLeadsDetails />} />
                            </Route>
                            <Route path={ALL_ROUTES.NOTIFICATIONS} element={<Notifications />}>
                                <Route path={ALL_ROUTES._ID} element={<SingleNotificationDetails />} />
                            </Route>

                            {/* <Route path={ALL_ROUTES.Investment_Management} element={<InvestmentManagement/>}></Route>
                            <Route path={ALL_ROUTES.ADD_Expense_Button._ID} element={<AddExpense/>}></Route> */}
                            <Route path={ALL_ROUTES.Investment_Management} element={<InvestmentManagement />}>
                                <Route path={ALL_ROUTES._ID} element={<AddExpense />} />
                            </Route>
                            <Route path={ALL_ROUTES.INVOICE_GENERATOR}>
                                <Route path={ALL_ROUTES._ID} element={<InvoiceGenerator />} />
                                <Route path={ALL_ROUTES.SALES_INVOICE}>
                                    <Route path={ALL_ROUTES._ID} element={<InvoiceGenerator/>} />
                                </Route>
                                <Route path={ALL_ROUTES.PURCHASE_INVOICE}>
                                    <Route path={ALL_ROUTES._ID} element={<InvoiceGenerator />} />
                                    {/* <Route path={ALL_ROUTES._ID} element={<InvoiceGenerator />} /> */}
                                </Route>
                            </Route>
                                <Route path={ALL_ROUTES.EDIT_PURCHASE_INVOICE}>
                                   <Route path ={ALL_ROUTES._ID} element={<AddEditInvoice/>}/>
                                </Route>
                                <Route path={ALL_ROUTES.EDIT_SALES_INVOICE}>
                                   <Route path ={ALL_ROUTES._ID} element={<AddEditInvoice/>}/>
                                </Route>
                            {/* <Route path={ALL_ROUTES.SALES_INVOICE} element={<h1>sales page</h1>}></Route>
                            <Route path={ALL_ROUTES.PURCHASE_INVOICE} element={<h1>purchase page</h1>}></Route> */}
                        </Route>

                        {/* Admin */}
                        <Route element={<RequireAuth allowedRoles={['superadmin', 'admin', 'manager']} />}>
                            <Route path={ALL_ROUTES.MAKERS} element={<MakerList />} />
                            <Route path={ALL_ROUTES.ADD_NEW_MAKE} element={<AddMake />} />

                            <Route path={ALL_ROUTES.MODELS} element={<ModelsList />} />
                            <Route path={ALL_ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
                            <Route path={ALL_ROUTES.PROFILE} element={<Profile />} />
                            {/* <Route path={ALL_ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} /> */}

                            {/* <Route path=':id' element={<EditVarientDetails />} /> */}
                            {/* </Route> */}
                            {/* <Route path='add-new-makers' element={<AddBrandAndVarient />} /> */}
                        </Route>
                    </Route>
                </Route>

                {/* Routes Missing */}
                <Route path={ALL_ROUTES.NOT_FOUND} element={<PageNotFound />} />

                {/* Unauthorised */}
                <Route path={ALL_ROUTES.UNAUTHORISED} element={<h3>You Are unauthorised</h3>} />
            </Routes>
        </div>
    )
}
export default IndexRouter;
