import { configureStore } from '@reduxjs/toolkit';
import { rootReducer,emailReducer } from './reducers';

const store = configureStore({
    reducer: {
        root: rootReducer,
        email:emailReducer,
    }
});

export default store;