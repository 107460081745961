import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { ALL_ROUTES } from "../constants/routesConfig";
import { getRole } from "../hooks/helperFunctions";
import { useEffect } from "react";

const RequireAuth = ({ allowedRoles }) => {
    const { setAuthRole } = useAuth();
    const location = useLocation();
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const roleId = userData?.role_Id
    const userRoleName = getRole(roleId)
    let userRole = [userRoleName]

    useEffect(()=>{
       setAuthRole({roleid:roleId})
    },[roleId])


    return (
        // auth?.role?.find(item => allowedRoles?.includes(item)) || userData?.role?.find(item => allowedRoles?.includes(item)) ? 
        token && userRole?.find(item => allowedRoles?.includes(item)) ?
            // token ?
            <Outlet /> 
        :
            token ?
                <Navigate to={ALL_ROUTES.UNAUTHORISED} state={{ from: location }} replace />
                :
                <Navigate to={ALL_ROUTES.LOGIN} state={{ from: location }} replace />
    );
}

export default RequireAuth;