import React from 'react'
import notpage from '../../src/images/page-not-fount.svg'


function PageNotFound() {
  return (
    <div>
     
      <img src={notpage} className="login_right_img m-auto mt-28" alt="notpagefound" />
      <h1 className='text-21 text-center font-semibold mt-4'>No Page Found</h1>
    </div>
  )
}

export default PageNotFound
